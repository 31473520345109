<template lang="pug">
transition(name="fade")
  om-toast.mb-5.mt-2(:closable="false" color="success" type="side")
    .alert-block-header.mb-3.align-items-center
    .alert-block.mb-2
      .alert-block-wrapper
        .alert-block-title.d-block {{ $t(`reIntegration.success`, { type: capitalizeFirstLetter(integrationType) }) }}
</template>
<script>
  import { capitalizeFirstLetter } from '@/util';
  import reIntegrationMixin from '../reIntegration';

  export default {
    mixins: [reIntegrationMixin],
    props: {
      integrationType: {
        type: String,
        required: true,
      },
    },
    methods: {
      capitalizeFirstLetter,
    },
  };
</script>
